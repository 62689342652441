const ChairishContext = window.chairisher.context;

export const UNIT_TYPE_SAMPLE = 'SA';

/**
 * @returns {boolean} If private messaging is enabled
 */
export function getAreMessagesEnabled() {
    return ChairishContext.ARE_MESSAGES_ENABLED;
}

/**
 * @returns {string} The spotlight type code indicating a product is boosted
 */
export function getBoostSpotlightTypeCode() {
    return ChairishContext.BOOST_SPOTLIGHT_TYPE_CODE;
}

/**
 * @returns {Array.<string>} The spotlight type codes used for quoss assignments
 */
export function getQuossAssignmentSpotlightTypeCodes() {
    return ChairishContext.QUOSS_ASSIGNMENT_SPOTLIGHT_TYPE_CODES;
}

/**
 * @returns {string} URL used to retrieve the "More Like This" grid/rail
 */
export function getMoreLikeThisUrl() {
    return ChairishContext.MLT_URL;
}

/**
 * @param {string} addressType The order item address type
 * @returns {string|undefined} The display text for the given address type
 */
export function getOrderItemAddressType(addressType) {
    return ChairishContext.ORDER_ITEM_ADDRESS_TYPES_JSON[addressType];
}

/**
 * @returns {string} URL used to retrieve Product Data
 */
export function getProductDataUrl() {
    return ChairishContext.PRODUCT_DATA_URL;
}

/**
 * @returns {string} URL used to retrieve the "Product Line" grid/rail
 */
export function getProductLineUrl() {
    return ChairishContext.PRODUCT_LINE_URL;
}

/**
 * @returns {string} The URL for generating an offer seller estimate based on a product
 */
export function getProductOfferSellerEstimateUrl() {
    return ChairishContext.PRODUCT_OFFER_SELLER_ESTIMATE_URL;
}

/**
 * @returns {string} The endpoint to hit for product shipping options
 */
export function getShippingOptionsUrl() {
    return ChairishContext.shippingOptionsUrl;
}

/**
 * @returns {string} URL used to retrieve the "Tastemaker" grid/rail
 */
export function getTastemakerUrl() {
    return ChairishContext.TASTEMAKER_URL;
}

/**
 * @returns {string} URL used to retrieve the "You Might Also Like" grid/rail
 */
export function getYmalUrl() {
    return ChairishContext.YMAL_URL;
}

/**
 * @param {number} orderItemId The id of the order item to get the URL for
 * @returns {string|undefined} The url of the You May Also Like endpoint, if any
 */
export function getYmalOrderItemUrl(orderItemId) {
    return ChairishContext.ORDER_ITEM_ID_TO_YMAL_URL_JSON
        ? ChairishContext.ORDER_ITEM_ID_TO_YMAL_URL_JSON[orderItemId]
        : undefined;
}

/**
 * @returns {string} URL used to retrieve the "Recently Viewed" grid/rail
 */
export function getRecentlyViewedUrl() {
    return ChairishContext.RECENTLY_VIEWED_URL;
}

/**
 * @returns {boolean} True indicates favorite hearts can be rendered on this page
 */
export function canRenderFavoriteHearts() {
    return ChairishContext.CAN_RENDER_FAVORITE_HEARTS !== false;
}

/**
 * @returns {boolean} True indicates folder icons can be rendered on this page
 */
export function canRenderFolderIcons() {
    return ChairishContext.CAN_RENDER_FOLDER_ICONS !== false;
}

/**
 * @param {Array=} filterByProductIds Optional list of product ids to filter the product search results by
 * @param {Boolean=} shouldSearchSku Optional boolean indicating if we should also search by SKU
 * @returns {string} The endpoint to hit when autocompleting a product query
 */
export function getProductAutocompleteEndpoint(filterByProductIds = null, shouldSearchSku = false) {
    let url = ChairishContext.PRODUCT_LOOKUP_URL;
    if (filterByProductIds || shouldSearchSku) {
        const params = new URLSearchParams();
        params.set('should_search_sku', shouldSearchSku);

        if (filterByProductIds && filterByProductIds.length > 0) {
            params.set('filter_by_product_ids', filterByProductIds);
        }

        url = `${url}?${params}`;
    }
    return url;
}

/**
 * @returns {string} The guid of the dealer selling the product
 */
export function getDealerGuid() {
    return ChairishContext.DEALER_GUID;
}

/**
 * @returns {number} The total number of favorites the current user has
 */
export function getFavoriteCount() {
    return ChairishContext.FAVORITES_COUNT || 0;
}

/**
 * @param {number} productId ID of the product to favorite
 * @returns {string} URL to use to favorite the product
 */
export function getFavoriteCreateUrl(productId) {
    return `/product/${productId}/favorite/create`;
}

/**
 * @param {number} productId ID of the product to unfavorite
 * @returns {string} URL to use to unfavorite the product
 */
export function getFavoriteDeleteUrl(productId) {
    return `/product/${productId}/favorite/delete`;
}

/**
 * @returns {string} The URL used to determine which products are favorited by the current user
 */
export function getFavoriteLookupUrl() {
    return ChairishContext.FAVORITE_LOOKUP_URL;
}

/**
 * @returns {Array.<number>} Collection of product ids that are in folders
 */
export function getFolderedProductIds() {
    return ChairishContext.FOLDERED_PRODUCT_IDS || [];
}

/**
 * @returns {Array.<Object>} Collection of indicator image srcset objects to use in the image viewer
 */
export function getImageViewerIndicatorSrcsets() {
    return ChairishContext.IMAGE_VIEWER_INDICATOR_SRCSETS || [];
}

/**
 * @returns {Array.<Object>} Collection of image srcset objects to use in the image viewer
 */
export function getImageViewerSrcsets() {
    return ChairishContext.IMAGE_VIEWER_SRCSETS || [];
}

/**
 * @returns {number|null} The max number of products that can be curated
 */
export function getMaxCurationProducts() {
    return ChairishContext.MAX_CURATION_PRODUCTS || null;
}

/**
 * @returns {number} The number of reviews for a given product
 */
export function getNumProductReviews() {
    return ChairishContext.NUM_PRODUCT_REVIEWS;
}

/**
 * @returns {string} The `sizes` attribute for images displayed in the product grid
 */
export function getProductGridImageSizes() {
    return ChairishContext.PRODUCT_GRID_IMAGE_SIZES;
}

/**
 * @returns {number|null} The average rating for a given product
 */
export function getProductRating() {
    return ChairishContext.PRODUCT_RATING || null;
}

/**
 * @param {number=} len Optional length to truncate the title to
 * @returns {string} The product title truncated to 50 chars; defaults to empty string
 */
export function getProductTitleTruncated(len = 50) {
    return ChairishContext.PRODUCT?.title?.substring(0, len) || '';
}

/**
 * @returns {Object} The object containing data about the embedded product
 */
export function getProductObject() {
    return ChairishContext.PRODUCT || {};
}

/**
 * @returns {Array.<Object>} The array containing multiple objects that contain data about the embedded products
 */
export function getProductObjectArray() {
    return ChairishContext.PRODUCTS;
}

/**
 * @returns {Object} Maping of product id to product object
 */
export function getProductIdToProductObjMap() {
    return ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP || {};
}

/**
 * @param {number} id The id of the product to look for
 * @returns {Object|undefined} The product object with the given id that is embedded in the JS context
 */
export function getProductObjectById(id) {
    const productObj = getProductIdToProductObjMap()[id];
    if (productObj) {
        return productObj;
    }

    // todo (CHAIR-14874): remove next line when all clients are gone
    return (getProductObjectArray() || []).find((productObject) => productObject.id === id);
}

/**
 * @param {string} key The object key or name of the product field to look for
 * @returns {string|array|null} The value of the field, if any
 */
export function getProductObjectValueByKey(key) {
    const productObj = getProductObject();
    return productObj && productObj[key] && productObj[key].value ? productObj[key].value : null;
}

/**
 * @returns {number} The id of the product being displayed
 */
export function getProductId() {
    return ChairishContext.PRODUCT_ID;
}

/**
 * @returns {string} the seller type for the owner of the product
 */
export function getProductSellerType() {
    return ChairishContext.PRODUCT_SELLER_TYPE;
}

/**
 * @returns {Object} Mapping of spotlight type code to CSS icon class
 */
export function getSpotlightTypesIconMap() {
    return ChairishContext.spotlight_types_icon_map;
}

/**
 * @returns {Object} Datastructure of spotlight tag choices in tree format
 */
export function getSpotlightTypesChoiceTree() {
    return ChairishContext.SPOTLIGHT_TYPES_CHOICE_TREE;
}

/**
 * @returns {string} URL to use to search for spotlight tag choices
 */
export function getSpotlightTypesLookupUrl() {
    return ChairishContext.SPOTLIGHT_TYPES_LOOKUP_URL;
}

/**
 * @param {number} variantId Id of the variant for which to retrieve data
 * @return {Object.<String, String>|undefined} Relevant data for the variant (e.g., price, title, dimensions)
 */
export function getDataForVariantId(variantId) {
    return (ChairishContext.variantIdToVariantDataMap || {})[variantId];
}

/**
 *
 * @returns Object containing all variant options
 */
export function getVariantChoices() {
    return ChairishContext.variantOptions;
}

/**
 * @param {number} productId The product id to check
 * @returns {boolean} True indicates the product is in a folder, false otherwise
 */
export function isProductIdInFolder(productId) {
    return getFolderedProductIds().includes(productId);
}

/**
 * @returns {boolean} True indicates the product has a sample as one of their variants, false otherwise
 */
export function isSampleAvailable() {
    const variantIdToVariantDataMap = ChairishContext.variantIdToVariantDataMap || {};
    return !!Object.keys(variantIdToVariantDataMap).find(
        (key) => variantIdToVariantDataMap[key].unit_type === UNIT_TYPE_SAMPLE,
    );
}

/**
 * @param {Array.<number>} folderedProductIds Collection of product ids that are in folders
 */
export function setFolderedProductIds(folderedProductIds) {
    ChairishContext.FOLDERED_PRODUCT_IDS = folderedProductIds;
}

/**
 * @param {Object} newProductIdToProductObjMap The new mapping to overwrite the old mapping with
 */
export function setProductIdToProductObjMap(newProductIdToProductObjMap) {
    ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP = newProductIdToProductObjMap;
}

/**
 * @returns {string} A URL key that indicates whether the user was redirected from the offer create page.
 */
export function getMaoRedirectKey() {
    return ChairishContext.MAO_REDIRECT_KEY;
}

/**
 * Updates the global data structure to indicate whether or not a product is "foldered"
 *
 * @param {number} productId The product id to update
 * @param {boolean} isFoldered True indicates the product is "foldered", false otherwise
 */
export function updateFolderedProductId(productId, isFoldered) {
    const index = ChairishContext.FOLDERED_PRODUCT_IDS.indexOf(productId);

    if (index === -1 && isFoldered) {
        ChairishContext.FOLDERED_PRODUCT_IDS.push(productId);
    } else if (index !== -1 && !isFoldered) {
        ChairishContext.FOLDERED_PRODUCT_IDS.splice(index, 1);
    }
}

/**
 * Updates a given product object in the JS context
 *
 * @param {Object} productObj
 */
export function updateProductObject(productObj) {
    const map = getProductIdToProductObjMap();
    map[productObj.id] = productObj;
    setProductIdToProductObjMap(map);
}

/**
 * @param {Object} newProductIdToProductObjMap The new mapping to update the old mapping with
 */
export function updateProductIdToProductObjMap(newProductIdToProductObjMap) {
    ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP = {
        ...getProductIdToProductObjMap(),
        ...newProductIdToProductObjMap,
    };
}

/**
 * Updates the list of products in context.
 * Either updates or pushes to the list depending on if the product is already present.
 *
 * @param {Array.<ProductModel>} products The products
 */
export function updateProductObjectArray(products) {
    products.forEach((newProduct) => {
        const existingProduct = getProductObjectById(newProduct.id);
        if (existingProduct) {
            const indexToReplace = ChairishContext.PRODUCTS.indexOf(existingProduct);
            ChairishContext.PRODUCTS[indexToReplace] = newProduct;
        } else {
            ChairishContext.PRODUCTS.push(newProduct);
        }
    });
}
