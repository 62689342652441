import $ from 'jquery';

import CookieUtils from 'chairisher/util/cookie';

export default function ensureCsrf() {
    const csrfToken = CookieUtils.getValueFromCookie('csrftoken');
    $('form[method=post]').each((i, el) => {
        const $form = $(el);
        const $csrfField = $form.find('[name=csrfmiddlewaretoken]');
        if ($csrfField.length > 0) {
            $csrfField.val(csrfToken);
        } else {
            $form.append(
                $('<input/>', {
                    type: 'hidden',
                    name: 'csrfmiddlewaretoken',
                    value: csrfToken,
                }),
            );
        }
    });
}
