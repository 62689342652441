import $ from 'jquery';

import ChairishABTest from 'chairisher/abtest';

/**
 * AB Test for determining if displaying the favorite count to non-admin users will incentivize them to favorite more products.
 **
 * TODO: (CHAIR-16499) Delete file after AB test
 */
export default class FavoriteCountTest extends ChairishABTest {
    static getExperimentName() {
        return 'FAVORITE_COUNT_TEST';
    }

    variation() {
        $('.js-favorite-button-control').addClass('hidden');
        $('.js-favorite-button-variant').removeClass('hidden');
    }
}
