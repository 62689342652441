import $ from 'jquery';

import AlerterView from 'chairisher/view/alerter';

import ensureCsrf from 'chairisher/util/csrf';

import { getAccountDataUrl } from 'chairisher/context/account';
import { getAccountDataURLSearchParams } from 'chairisher/context/tracking';
import { getJSON } from 'chairisher/util/fetch';

/**
 * @param {Array<String>=} keys Optional keys if updating specific context properties
 */
export default async function updateAccountDataContext(keys = []) {
    try {
        const params = getAccountDataURLSearchParams();

        if (keys.length > 0) {
            params.append('account_data_keys', keys);
        }

        const accountDataResponse = await getJSON(getAccountDataUrl(params));
        $.extend(window.chairisher.context, accountDataResponse);
        ensureCsrf();
    } catch (error) {
        AlerterView.error(error.firstMessage);
    }
}
